class Utilities {
	/* global $ */
	constructor() {
		$('#nav-main li a[href="/' + location.pathname.split("/")[1] + '"]').addClass('active');
		console.log("%c\u2713 %cUtilities %cModule %cis installed","font-size:2em","font-weight:bold; font-size:1.5em;color: #ffc107;"," color: #444; font-weight:bold; font-size:1.5em;","font-weight:normal; font-size:1em;");
	}
	static setScrolledClass(el) {
		if ($(window).scrollTop() > 0) {
			el.addClass("scrolled");
		} else {
			el.removeClass("scrolled");
		}
	}

	static displayToggle(el) {
		el.toggle(100);
	}
}